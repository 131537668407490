const router = {
  props(route) {
    const { params, query } = route

    if (query.adjuster && query.adjuster === 'true') {
      params.adjuster = true
    }

    if (!params.subId && params.subId !== 0) {
      params.subId = '*'
    }

    return {
      ...params,
      query,
    }
  },
}

export default router
