const paths = {
  ADMANAGER: {
    BASE: 'admanager',
    CREATIVES: 'creatives',
    LINEITEMS: 'lineitems',
    NETWORKS: 'networks',
    ORDERS: 'orders',
  },
  DOCS: {
    BASE: 'docs',
    BACKLOG: 'backlog',
    DATA_REFRESH: 'data-refresh',
    DEPRECATION: 'deprecation',
    DOCS: 'docs',
    RELEASES: 'releases',
    PRIVACY_POLICY: 'privacy-policy',
    WHITELIST: 'whitelist',
  },
  DELIVERY: {
    BASE: 'delivery',
    DISCREPANCY: 'discrepancy',
    PACING: 'pacing',
  },
  INTERNAL: {
    BASE: 'internal',
    ORGANIZATION: 'organization',
    OVERVIEW: 'overview',
    PERSON: 'person',
    PROJECT: 'project',
    TIME: 'time',
  },
  PROGRAMMATIC: {
    BASE: 'programmatic',
    HEALTH: {
      BASE: 'health',
      ADX: 'health/adx',
      NETWORK: 'health/network',
    },
    YIELD: {
      BASE: 'yield',
      PARTNER: 'yield/partner',
    },
  },
  CUSTOM_REPORTS: {
    BASE: 'custom-reports',
    CAMPAIGN_TRACKING: 'campaign-tracking',
    CAMPAIGN_TRACKING_1: 'campaign-tracking-1',
    CAMPAIGN_TRACKING_2: 'campaign-tracking-2',
    MONTHLY_PROGRESS: 'monthly-progress',
    PROGRESS_REPORT: 'progress-report',
    REVENUE: 'revenue',
    REVENUE_AT_RISK: 'revenue-at-risk',
    SCHNEPS_MID_MONTH: 'schneps-mid-month',
  },
}

export default paths
